<template>
  <div class="container">
    <div class="orderStatus" style="position: relative">
      <Progress
        :active-index="actIndex"
        :step-list="stepList"
      />
      <component
        v-if="actIndex === 1"
        :is="currentOrderStatusComponent"
        ref="CodeEqual"
        :order-no="orderNo"
        :create-time="createTime"
      />
      <component
        v-if="actIndex === 2"
        :is="currentOrderStatusComponent2"
        ref="CodeEqual2"
        :order-no="orderNo"
        :create-time="createTime"
      />
      <div v-if="actIndex === 1" style="position: absolute;z-index: 1;top: 124px;left: 48px;color:red">审核中，请稍后</div>
    </div>
    <div class="main">
      <Descriptions
        ref="project"
        class="description"
        title="企业认证"
        :data-source="projectData"
        :time-field-list="projectDataTimeFieldList"
        :picture-field-list="projectDataPictureFieldList"
        :amount-field-list="projectDataAmountFieldList"
      >
        <template slot="extra">
          <!-- <div class="titleDocument">
            <a-icon
              type="exclamation-circle"
            /><p>加急认证请拨打客服电话</p>
          </div> -->
        </template>
      </Descriptions>
    </div>
    <a-modal
      v-model="isFlag"
      :cancel-button-props="{ style: { display: 'none' } }"
      @ok="handleOk"
      centered
      :mask-closable="false"
      :keyboard="false"
      ok-text="确定"
      title="认证失败"
    >
      <p>认证审核失败：{{ this.$store.state.info.auditMessage }}，请重新提交认证！</p>
    </a-modal>
  </div>
</template>

<script>
import { Icon } from 'ant-design-vue';
import Progress from '@/components/Progress';
import DocumentDialog from '@/components/DocumentDialog';
import Descriptions from '@/components/Descriptions';
import OrderStatusCodeEqual from './components/CodeEqual';
import OrderStatusCodeEqual2 from './components/CodeEqual2';
import { entInfo } from '@/api/entCertification/entCertification.js';
import { dateFormatDay } from '@/utils/format.js';

export default {
  name: 'EntCertification',
  components: {
    aIcon: Icon,
    Progress,
    Descriptions,
    DocumentDialog,
    OrderStatusCodeEqual,
    OrderStatusCodeEqual2,
  },
  data() {
    return {
      isFlag: false,
      orderNo: '',
      createTime: 0,
      actIndex: null,
      insureInfoDialog: {
        visible: false,
      },
      invitationLetterDialog: {
        visible: false,
      },
      stepList: [{
        name: '提交审核',
        flag: 0,
      }, {
        name: '认证审核中',
        flag: 1,
        real: 2,
      }, {
        name: '认证成功',
        flag: 2,
        real: 1,
      }],
      projectData: {},
      projectDataTimeFieldList: [ 'createTime' ],
      projectDataAmountFieldList: [ 'amount' ],
      projectDataPictureFieldList: [ 'businessFile', 'legalFile', 'authPersonFile' ],
    };
  },
  computed: {
    currentOrderStatusComponent() {
      return 'OrderStatusCodeEqual';
    },
    currentOrderStatusComponent2() {
      return 'OrderStatusCodeEqual2';
    },
  },
  mounted() {
    this.info();
  },
  methods: {
    handleOk() {
      this.$router.push({ name: 'entCertForm' });
    },
    info() {
      entInfo().then(res => {
        const data = res.data.data;
        this.actIndex = Number(res.data.data.certificationFlag);
        if (this.actIndex === 3) {
          this.$set(this.stepList[2], 'name', '认证失败')
          this.isFlag = true
        } else if (this.actIndex === 2) {
          this.actIndex = 1;
        } else if (this.actIndex === 1) {
          this.actIndex = 2;
        }
        let legalFile = [];
        let authPersonFile = [];
        if (data.legalPersonCardImage) {
          legalFile = data.legalPersonCardImage ? data.legalPersonCardImage.split(',') : null;
          authPersonFile = data.authorizerCardImage ? data.authorizerCardImage.split(',') : null;
        }
        this.projectData = {
          entName: data.companyName,
          creditCode: data.creditCode,
          appCompanyAddress: data.appCompanyAddress,
          appCompanyPhone: data.appCompanyPhone,
          legalName: data.legalName,
          legalCertNo: data.legalCertNo,
          identifyStart: dateFormatDay(data.identifyStart) + '~' + dateFormatDay(data.identifyExpiry),
          businessFile: [ process.env.VUE_APP_FIRST_URL + data.businessLicenseImage ],
          legalFile: [ process.env.VUE_APP_FIRST_URL + legalFile[0], process.env.VUE_APP_FIRST_URL + legalFile[1] ],
          authPersonFile: authPersonFile ? [ process.env.VUE_APP_FIRST_URL + authPersonFile[0], process.env.VUE_APP_FIRST_URL + authPersonFile[1] ] : '',
        };
        this.orderNo = data.orderNo;
        this.createTime = data.createTime;
      });
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.container {
  margin: 30px auto 60px;
}
.orderStatus{
  border-radius: 5px;
  overflow: hidden;
}
.main{
  margin-top: 20px;
  padding: 25px 50px 50px;
  background-color: #fff;
}
.titleDocument{
  color:#666;
  font-size: 16px;
}
.description+.description,.invoice{
  margin-top: 50px;
}
.titleDocument {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  display: flex;
  align-items: center;
  p {
    margin-left: 5px;
  }
}
</style>
