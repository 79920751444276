<template>
  <div class="descriptions">
    <PlateTitle title="企业认证">
      <template slot="extra">
        <slot name="extra" />
      </template>
    </PlateTitle>
    <div class="form">
      <a-form
        :form="form"
        :label-col="{ span: 10 }"
        :wrapper-col="{ span: 14 }"
        @submit="handleSubmit"
      >
        <div class="form-title">
          —————— <span class="txt">企业基本信息</span> ——————
        </div>
        <a-row>
          <a-col :span="8">
            <a-form-item label="企业名称">
              <a-input disabled v-decorator="['companyName']" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="企业统一社会信用代码">
              <div class="creditCode">
                <a-input disabled v-decorator="['creditCode']" />
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="单位电话">
              <a-input disabled v-decorator="['appCompanyPhone']" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="法定代表人">
              <a-input disabled v-decorator="['legalName']" />
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="法人证件类型">
              <a-select
                disabled
                v-decorator="['legalCertType']"
                style="width: 200px"
              >
                <a-select-option
                  v-for="item in legalCertTypeList"
                  :key="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="法人证件号">
              <a-input disabled v-decorator="['legalCertNo']" />
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="基本户银行账户">
              <a-input disabled v-decorator="['account']" />
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item label="基本户开户行名称">
              <a-input disabled v-decorator="['bankNo']" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="拓展人">
              <a-input
              disabled
                v-decorator="['extender']"
                placeholder="请输入拓展人"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="8">
            <a-form-item label="拓展机构">
              <a-input
              disabled
                v-decorator="['extendAgency']"
                placeholder="请输入拓展机构"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="form-title">
          —————— <span class="txt">开票信息</span> ——————
        </div>
        <a-row>
          <a-col :span="8">
            <a-form-item label="发票类型">
              <a-select
                disabled
                v-decorator="['invoiceType']"
                style="width: 200px"
              >
                <a-select-option
                  v-for="item in invoiceTypeList"
                  :key="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="纳税人识别号">
              <a-input disabled v-decorator="['cuscCode']" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="银行账号">
              <a-input disabled v-decorator="['bankAcc']" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="开户行名称">
              <a-input disabled v-decorator="['bankInfo']" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="联系地址">
              <a-input disabled v-decorator="['contactAddress']" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="电子邮箱">
              <a-input disabled v-decorator="['email']" />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="form-title">
          —————— <span class="txt">经办人信息</span> ——————
        </div>
        <a-row>
          <a-col :span="8">
            <a-form-item label="姓名">
              <a-input disabled v-decorator="['principalName']" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="身份证号">
              <a-input disabled v-decorator="['principalIdCard']" />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="手机号">
              <a-input disabled v-decorator="['principalPhone']" />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="form-title">
          —————— <span class="txt">附件信息</span> ——————
        </div>
        <a-row>
          <a-col :span="8">
            <a-form-item label="企业营业执照">
              <a-upload
                :action="VUE_APP_FIRST_URL + '/api/contract/upload'"
                :data="{ fileUrl: 'entCertForm' }"
                list-type="picture-card"
                :file-list="businessFile"
                @preview="handlePreview"
                @change="value => handleChange('business', value)"
              >
                <div v-if="businessFile.length < 1">
                  <a-icon type="plus" />
                </div>
              </a-upload>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                @cancel="handleCancel"
              >
                <img style="width: 100%" :src="previewImage" />
              </a-modal>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="法人身份证">
              <div style="display: flex;">
                <a-upload
                  :action="VUE_APP_FIRST_URL + '/api/contract/upload'"
                  :data="{ fileUrl: 'entCertForm' }"
                  list-type="picture-card"
                  :file-list="legalFile1"
                  @preview="handlePreview"
                  @change="value => handleChange('legal1', value)"
                >
                  <div v-if="legalFile1.length < 1">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">身份证正面</div>
                  </div>
                </a-upload>
                <a-upload
                  :action="VUE_APP_FIRST_URL + '/api/contract/upload'"
                  :data="{ fileUrl: 'entCertForm' }"
                  list-type="picture-card"
                  :file-list="legalFile2"
                  @preview="handlePreview"
                  @change="value => handleChange('legal2', value)"
                >
                  <div v-if="legalFile2.length < 1">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">身份证反面</div>
                  </div>
                </a-upload>
              </div>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                @cancel="handleCancel"
              >
                <img style="width: 100%" :src="previewImage" />
              </a-modal>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                @cancel="handleCancel"
              >
                <img style="width: 100%" :src="previewImage" />
              </a-modal>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="最近一期财务报表" class="labes" v-if="isfiles">
              <div class="uploads">
                <a-upload
                  class="value"
                  @preview="handledDownload"
                  @download="handledDownload"
                  :default-file-list="defaultFileList"
                  name="file"
                  :showUploadList="{
                    showDownloadIcon: true,
                    showRemoveIcon: false,
                    showPreviewIcon: false
                  }"
                />
              </div>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="10">
            <a-form-item label="企业建筑资质">
              <span class="ant-form-text">
                支持jpg、png格式文件，文件不能大于5M
              </span>
              <UploadBtn :isDisable="true" :list.sync="imgArr"></UploadBtn>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="开户许可证">
              <span class="ant-form-text">
                支持jpg、png格式文件，文件不能大于5M
              </span>
              <UploadBtn :isDisable="true" :list.sync="imgArr1"></UploadBtn>
            </a-form-item>
          </a-col>
        </a-row>

        <a-form-item :wrapper-col="{ span: 12, offset: 10 }" class="entBtn">
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import PlateTitle from "@/components/PlateTitle";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import moment from "moment";
moment.locale("zh-cn");
import UploadBtn from "@/components/UploadBtn/index";

import { submitApproveInfo } from "@/api/entCertification/entCertification.js";
import { entInfo } from "@/api/entCertification/entCertification.js";
import { checkYSTService } from "@/assets/script/CAjs/caLogin.js";
import { dateFormatDay, downloadFile } from "@/utils/format.js";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  name: "EntCertForm",
  components: {
    PlateTitle,
    UploadBtn
  },
  data() {
    return {
      imgArr: [],
      imgArr1: [],
      time: 2,
      timer: "",
      caFlag: "",
      spinAddress: false,
      visible: false,
      visibleMsg: false,
      locale,
      form: this.$form.createForm(this, { name: "coordinated" }),
      previewVisible: false,
      identifyDisabled: false,
      defaultLongTime: false,
      previewImage: "",
      file: {},
      basicFile: [],
      businessFile: [], // 企业营业执照
      legalFile1: [], // 法人身份证正面照
      legalFile2: [], // 法人身份证反面照
      companyInfo: {
        caFlag: "",
        companyName: "",
        creditCode: ""
      },
      legalCertTypeList: [
        // 法人证件类型列表
        { label: "身份证", value: "1" },
        { label: "统一社会信用代码", value: "2" },
        { label: "护照", value: "3" }
      ],
      invoiceTypeList: [
        // 发票类型列表
        { label: "纸质（普票）", value: "1" },
        { label: "电子（普票）", value: "2" },
        { label: "纸质（专票）", value: "3" }
      ],
      // validateCompanyName: {
      //   rules: [{ type: 'string', required: true, message: "请输入投标企业名称" }]
      // },
      // validateCreditCode: {
      //   rules: [{ type: 'string', required: true, message: "请输入投标企业统一社会信用代码" }]
      // },
      // validateEstablishDate: {
      //   rules: [{ type: 'object', required: true, message: "请选择成立日期" }]
      // },
      // validateLegalName: {
      //   rules: [{ type: 'string', required: true, message: "请输入法定代表人姓名" }]
      // },
      // validateLegalCertType: {
      //   rules: [{ type: 'string', required: true, message: "请选择法人证件类型" }]
      // },
      // validateLegalCertNo: {
      //   rules: [{ type: 'string', required: true, message: "请输入法人证件号" }]
      // },
      // validateRegisteredCapital: {
      //   rules: [{ type: 'string', required: true, message: "请输入注册资本" }]
      // },
      // validateAppCompanyAddress: {
      //   rules: [{ type: 'string', required: true, message: "请输入企业地址" }]
      // },
      // validateAccount: {
      //   rules: [{ type: 'string', required: true, message: "请输入基本户银行账户" }]
      // },
      // validateBankNo: {
      //   rules: [{ type: 'string', required: true, message: "请输入基本户开户行名称" }]
      // },
      // validateAppCompanyPhone: {
      //   rules: [{ type: 'string', required: true, message: "请输入公司电话" }]
      // },
      // validateIdentifyStart: {
      //   rules: [{ type: 'object', required: true, message: "请选择营业执照有效开始日期" }]
      // },
      // validateInvoiceType: {
      //   rules: [{ type: 'number', required: true, message: "请选择发票类型" }]
      // },
      // validateCuscCode: {
      //   rules: [{ type: 'string', required: true, message: "请输入纳税人识别号" }]
      // },
      // validateBankAcc: {
      //   rules: [{ type: 'string', required: true, message: "请输入银行账号" }]
      // },
      // validateBankInfo: {
      //   rules: [{ type: 'string', required: true, message: "请输入开户行名称" }]
      // },
      // validateContactAddress: {
      //   rules: [{ type: 'string', required: true, message: "请输入联系地址" }]
      // },
      // validateMailAddress: {
      //   rules: [{ type: 'string', required: true, message: "请输入邮寄地址" }]
      // },
      // validateEmail: {
      //   rules: [{ type: 'string', required: true, message: "请输入电子邮箱" }]
      // },
      // validatePhoneNo: {
      //   rules: [{ type: 'string', required: true, message: "请输入联系电话" }]
      // },
      // validatePrincipalName: {
      //   rules: [{ type: 'string', required: true, message: "请输入经办人姓名" }]
      // },
      // validatePrincipalIdCard: {
      //   rules: [{ type: 'string', required: true, message: "请输入经办人身份证号" }]
      // },
      // validatePrincipalPhone: {
      //   rules: [{ type: 'string', required: true, message: "请输入经办人手机号" }]
      // },
      VUE_APP_FIRST_URL: "",
      defaultFileList: [],
      isfiles: false
    };
  },
  mounted() {
    this.VUE_APP_FIRST_URL = process.env.VUE_APP_FIRST_URL;
    this.initData();
    if (this.$route.query && this.$route.query.companyInfo) {
      const companyInfo = JSON.parse(
        decodeURIComponent(this.$route.query.companyInfo)
      );
      this.companyInfo = { ...companyInfo };
      this.caFlag = this.companyInfo.caFlag;
      this.form.setFieldsValue({
        companyName: this.companyInfo.companyName
          ? this.companyInfo.companyName
          : "",
        creditCode: this.companyInfo.certCode ? this.companyInfo.certCode : ""
      });
      if (this.companyInfo.caFlag === "1") {
        this.timer = setInterval(this.countDown, 1000);
      }
    }
  },
  methods: {
    handledDownload(file) {
      if (file.name) {
        downloadFile(
          file.url,
          file.name.substring(0, file.name.lastIndexOf(".")),
          file.name.match(/.[^.]+$/)[0].split(".")[1]
        );
      } else {
        this.$message.warning("文件名缺失，无法下载！");
      }
    },
    validateIdentifyExpiry(rule, value, callback) {
      if (!this.identifyDisabled) {
        if (!value) {
          this.$message.warning("请选择营业执照有效到期日期");
          callback("请选择营业执照有效到期日期");
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    // 初始化页面
    initData() {
      entInfo().then(res => {
        const data = res.data.data;
        // if (!data.establishDate) {
        //   delete data.establishDate;
        // }
        if (data.identifyStart === null) {
          delete data.identifyStart;
        }
        if (data.identifyExpiry === null) {
          delete data.identifyExpiry;
        }
        if (data.identifyExpiry === 4102358400000) {
          this.identifyDisabled = true;
          this.defaultLongTime = true;
        }

        let arr = JSON.parse(res.data.data.qualificationImage);
        let arr1 = JSON.parse(res.data.data.accountLicenceImage);
        
        this.imgArr = arr.map((item, index) => {
          return {
            uid: index,
            name: "image.png",
            status: "done",
            url: this.VUE_APP_FIRST_URL + item.filePath
          };
        });

        this.imgArr1 = arr1.map((item, index) => {
          return {
            uid: index,
            name: "image.png",
            status: "done",
            url: this.VUE_APP_FIRST_URL + item.filePath
          };
        });

        this.form.setFieldsValue({
          companyName: data.companyName, // 投标企业名称
          creditCode: data.creditCode, // 投标企业统一社会信用代码
          establishDate: data.establishDate
            ? dateFormatDay(data.establishDate)
            : null, // 成立日期
          legalName: data.legalName, // 法定代表人
          legalCertType: data.legalCertType ? data.legalCertType : "身份证", // 法人证件类型
          legalCertNo: data.legalCertNo, // 法人证件号
          registeredCapital: data.registeredCapital, // 注册资本
          appCompanyAddress: data.appCompanyAddress, // 企业地址
          account: data.account, // 基本户银行账户
          bankNo: data.bankNo, // 基本户开户行名称
          appCompanyPhone: data.appCompanyPhone, // 公司电话
          identifyStart: data.identifyStart
            ? dateFormatDay(data.identifyStart)
            : null, // 营业执照有效开始日期
          identifyExpiry: data.identifyExpiry
            ? dateFormatDay(data.identifyExpiry)
            : null, // 营业执照有效到期日期
          invoiceType: data.invoiceType ? data.invoiceType : 1, // 发票类型：1 纸质（普票）2  电子（普票）3 纸质（专票）
          cuscCode: data.cuscCode, // 纳税人识别号
          bankAcc: data.bankAcc, // 银行账号
          bankInfo: data.bankInfo, // 开户行名称
          contactAddress: data.contactAddress, // 联系地址
          mailAddress: data.mailAddress, // 邮寄地址
          email: data.email, // 电子邮箱
          phoneNo: data.phoneNo, // 联系电话
          principalName: data.principalName, // 经办人姓名
          principalIdCard: data.principalIdCard, // 经办人身份证号
          principalPhone: data.principalPhone, // 经办人手机号
          lastFinalStatus: data.lastFinalStatus, // 是否显示附件
          extendAgency: data.extendAgency,
          extender: data.extender,
          applyResultStr: data.applyResultStr // 提示信息
        });
        try {
          const files = JSON.parse(data.lastApplyFiles);
          this.defaultFileList = files.map((item, index) => {
            return {
              name: item.fileName,
              url: item.filePath,
              uid: index,
              status: "done"
            };
          });
        } catch {}
        this.isfiles = data.lastApplyFiles ? true : false;
        if (data.legalPersonCardImage !== null) {
          this.file = {
            businessFile: data.businessLicenseImage,
            legalFile: data.legalPersonCardImage.split(",")
          };
          try {
            this.businessFile = [
              {
                uid: "001",
                name: "image.png",
                status: "done",
                url:
                  this.VUE_APP_FIRST_URL +
                  JSON.parse(data.businessLicenseImage)[0].filePath
              }
            ];
          } catch {}
          try {
            this.legalFile1 = [
              {
                uid: "002",
                name: "image.png",
                status: "done",
                url:
                  this.VUE_APP_FIRST_URL +
                  JSON.parse(data.legalPersonCardImage)[0].filePath
              }
            ];
          } catch {}
          try {
            this.legalFile2 = [
              {
                uid: "003",
                name: "image.png",
                status: "done",
                url:
                  this.VUE_APP_FIRST_URL +
                  JSON.parse(data.legalPersonCardImage)[1].filePath
              }
            ];
          } catch {}
        }
      });
    },
    handleCA() {
      checkYSTService("company");
      this.timer = "";
    },
    countDown() {
      if (this.time > 0) {
        this.time--;
      } else {
        this.time = 0;
        this.visible = false;
        clearInterval(this.timer);
        this.$router.push({ name: "user" });
      }
    },
    handleOk() {},
    handleDown() {
      this.visible = false;
    },
    longTime(e) {
      this.defaultLongTime = !this.defaultLongTime;
      if (e.target.checked) {
        this.identifyDisabled = true;
      } else {
        this.identifyDisabled = false;
      }
    },
    // 下一步
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (err) {
          return;
        }
        if (this.businessFile.length === 0) {
          this.$message.warning("请上传营业执照");
          return false;
        }
        if (this.legalFile1.length === 0 || this.legalFile2.length === 0) {
          this.$message.warning("请上传法人身份证");
          return false;
        }

        this.visibleMsg = true;
      });
    },
    msgOk() {
      this.form.validateFields((err, values) => {
        if (!err) {
          const info = this.$store.state.info;
          this.businessFile.forEach(item => {
            if (item.response) {
              this.file.businessFile = item.response.data.url;
            }
          });
          if (!this.file.legalFile) {
            this.file.legalFile = [];
          }
          if (this.legalFile1[0].response) {
            this.file.legalFile[0] = this.legalFile1[0].response.data.url;
          }
          if (this.legalFile2[0].response) {
            this.file.legalFile[1] = this.legalFile2[0].response.data.url;
          }
          const params = {
            ...values,
            customerId: info.id,
            establishDate: dateFormatDay(values.establishDate),
            identifyStart: dateFormatDay(values.identifyStart),
            identifyExpiry: dateFormatDay(values.identifyExpiry),
            businessLicenseImage: this.file.businessFile,
            legalPersonCardImage: this.file.legalFile.join(","),
            certificationFlag: info.certificationFlag
          };
          if (this.identifyDisabled) {
            params.identifyExpiry = "2099-12-31";
          }
          submitApproveInfo(params).then(res => {
            this.visibleMsg = true;
            if (res.data.status === "FAIL") {
              this.$message.warning(res.data.errorMsg);
            } else {
              this.$message.success("企业认证提交成功");
              this.$router.push({ name: "entCertification" });
            }
          });
        }
      });
    },
    msgDown() {
      this.visibleMsg = false;
    },

    // 取消预览
    handleCancel() {
      this.previewVisible = false;
    },
    // 预览
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    // 上传
    handleChange(type, { fileList }) {
      if (type === "business") {
        if (fileList.length > this.businessFile.length) {
          if (
            !(
              fileList[fileList.length - 1].type === "image/jpeg" ||
              fileList[fileList.length - 1].type === "image/png"
            )
          ) {
            this.$message.warning("请上传正确格式的文件。");
            return false;
          }
        }
        this.businessFile = fileList;
      } else if (type === "legal1") {
        if (fileList.length > this.legalFile1.length) {
          if (
            !(
              fileList[fileList.length - 1].type === "image/jpeg" ||
              fileList[fileList.length - 1].type === "image/png"
            )
          ) {
            this.$message.warning("请上传正确格式的文件。");
            return false;
          }
        }
        this.legalFile1 = fileList;
      } else if (type === "legal2") {
        if (fileList.length > this.legalFile2.length) {
          if (
            !(
              fileList[fileList.length - 1].type === "image/jpeg" ||
              fileList[fileList.length - 1].type === "image/png"
            )
          ) {
            this.$message.warning("请上传正确格式的文件。");
            return false;
          }
        }
        this.legalFile2 = fileList;
      }
    }
  }
};
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

.ant-upload-select-picture-card i {
  color: #999;
  font-size: 32px;
}

.ant-upload-select-picture-card {
  margin-top: 8px;
  color: #666;
}

.ant-upload-picture-card-wrapper {
  width: 125px;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 700px;
  margin: 30px auto;
  background-color: #fff;

  .ant-form-text {
    display: block;

    &::before {
      display: inline-block;
      content: "*";
      line-height: 1;
      margin-right: 4px;
      color: #f5222d;
      font-size: 14px;
      font-family: SimSun, sans-serif;
    }
  }

  /deep/ .ant-modal-header {
    border: none;
  }

  /deep/ .ant-modal-footer {
    height: 76px;
    border: none;
    background-color: #f7faff;
  }

  /deep/ .ant-modal-body {
    padding: 0 24px;
  }

  /deep/ .ant-spin-container {
    padding-top: 40px;
    padding-bottom: 64px;
    border-top: 1px solid #ebf2f7;
  }

  /deep/ .ant-btn {
    width: 100px;
    height: 36px;
    margin-top: 10px;
    color: #007ce2;
    font-size: 14px;
    font-weight: 400;
    font-family: Microsoft YaHei;
    border: 1px solid #007ce2;
    border-radius: 3px;
  }

  /deep/ .ant-btn-primary {
    margin-right: 14px;
    margin-left: 20px;
    color: #fff;
    background: #007ce2;
  }
}

.wain {
  position: relative;
  width: 1100px;
  height: 70px;
  margin: 30px auto 0 auto;
  background: #fffbf3;

  .wainIcon {
    position: absolute;
    top: 16px;
    left: 30px;
    width: 12px;
    height: 12px;
    color: #e7b24b;
  }

  p {
    margin: 12px 12px 12px 53px;
    color: #e7b24b;
    font-size: 14px;
    font-weight: 400;
    font-family: Microsoft YaHei;
  }
}

.main {
  width: 1100px;
  margin: auto;
  padding: 40px 0px 20px;
}

.form {
  margin-top: 20px;

  .form-title {
    color: #ebebeb;
    text-align: center;

    .txt {
      color: #333;
      font-size: 16px;
    }
  }

  /deep/ .ant-alert-warning {
    width: 500px;
    margin: 0 auto;
    padding: 8px 8px 8px 35px;
    color: #999;

    span {
      color: #666;
    }
  }

  /deep/ .ant-upload {
    width: 103px;
    height: 103px;
  }

  /deep/ .ant-form-item {
    margin: 20px 0;

    span {
      color: #999;
      font-size: 14px;
      font-weight: 400;
      font-family: Microsoft YaHei;
    }

    .creditCode {
      display: flex;
      flex-direction: column;
    }

    .same {
      line-height: 14px;
      margin-top: 7px;
    }
  }

  /deep/ .ant-form-item label {
    margin-right: 10px;
    color: #666;
    font-size: 14px;
    font-weight: 400;
    font-family: Microsoft YaHei;
  }

  .identify {
    span {
      margin: 0 3px;
    }

    /deep/ .ant-input {
      width: 150px;
    }
  }

  /deep/ .ant-input {
    width: 200px;
    height: 36px;
    padding-left: 8px;
    border: 1px solid #e1e8ef;
    border-radius: 3px;
    background: #ffffff;
  }

  /deep/ .ant-btn {
    width: 200px;
    height: 44px;
    margin: 40px 0 60px 0;
    border-radius: 4px;
    background: #007ce2;

    span {
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      font-family: Microsoft YaHei;
    }
  }

  .entBtn {
    width: 1080px;
    margin: 0 auto;
    border-top: 1px solid #ebf2f7;
  }
}

.CAcode {
  .CAlogin {
    position: relative;
    display: flex;
    margin: 0 auto;
    background: #fffbf3;

    .anticon {
      position: absolute;
      top: 14px;
      left: 10px;
      width: 14px;
      height: 14px;
      color: #e7b24b;
    }

    div {
      padding: 10px 0 9px 33px;
      color: #e7b24b;
      font-size: 14px;
      font-weight: 400;
      font-family: Microsoft YaHei;
    }
  }

  /deep/ .ant-input {
    width: 330px;
    height: 36px;
    margin: 15px 0;
    padding-left: 8px;
    border: 1px solid #e1e8ef;
    border-radius: 3px;
    background: #ffffff;
  }
}
/deep/ .ant-input[disabled] {
  color: #333;
  border: none;
  background: #fff;
}
/deep/ .ant-select-arrow .ant-select-arrow-icon svg {
  display: none;
}
/deep/ .ant-select-disabled .ant-select-selection {
  background: #fff;
  color: #333;
  border: none;
}
/deep/ .form .ant-form-item span {
  color: #333;
}
/deep/ .anticon-delete {
  display: none;
}
.labes {
  display: flex;
  flex-wrap: wrap;
  /deep/ .ant-form-item-label {
    width: 100%;
    padding-left: 20px;
    text-align: left;
  }
  /deep/ .ant-form-item-control-wrapper {
    padding-left: 20px;
  }
}
</style>
